<template>
  <ContactForm v-bind="$attrs" msg-label="event details" v-on="$listeners">
    <template #success-msg="{ dismiss }">
      <h1 class="grey--text">Thank you.</h1>
      <p class="r-title">We'll get back to you soon.</p>
      <BaseButton class="ml-0" color="bubs-purple" small @click="dismiss"
        >Sounds Good!</BaseButton
      >
    </template>
    <template #submit-btn="{ sending }"
      ><BaseButton
        color="bubs-purple"
        class="ml-0"
        type="submit"
        :loading="sending"
        >Send</BaseButton
      ></template
    >
  </ContactForm>
</template>

<script>
import ContactForm from '@components/ContactForm'

export default {
  name: 'BubsCateringForm',
  components: {
    ContactForm,
  },
  data() {
    return {}
  },
}
</script>
